<template>
<div class="content-wrapper overflow-visible">
  <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

  <div class="content">
    <template v-if="isListDetail">
      <div class="page-title pt-0 pb-3">
        <router-link :to="{ name: 'RanapPasienPerawatan' }" class="text-dark">
          <i class="icon-arrow-left52 mr-1"></i>
          <span class="font-weight-semibold">Kembali</span>
        </router-link>
      </div>

      <div class="row g-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="d-flex align-items-center">
                <h5 class="card-title font-weight-semibold mr-auto">Monitoring Kajian Terminal</h5>
                <a href="javascript:;" v-if="moduleRole('add') && !has_draft_data" @click="$router.push({ ...$route, query: { viewType: 'FORM' } })" class="btn bg-blue-600 btn-labeled btn-labeled-left">
                  <b><i class="icon-plus2"></i></b>Buat Kajian Terminal
                </a>
              </div>
            </div>

            <div class="card-body">
              <table class="table table-bordered table-sm patient-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Tanggal/Jam</th>
                    <th>Perawat</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in tData.data" :key="`datas-${k}`">
                    <td>
                      <span class="font-weight-semibold" v-b-tooltip.hover title="Lihat Detail">{{ k+1 }}</span>
                    </td>
                    <td>
                      {{ (v.updated_date||v.created_date) | moment('LLL') }}

                      <p class="mb-0 mt-2" v-if="v.is_draft == 'Y'">
                        <b-badge variant="danger">Draft</b-badge>
                      </p>
                    </td>
                    <td>{{ v.nama_petugas }}</td>
                    <td>
                      <a href="javascript:;" @click="openDetail(v)" v-if="v.is_draft == 'N'" class="btn btn-icon alpha-blue border-blue mr-2" v-b-tooltip.hover title="Lihat Kajian Terminal"><i class="icon-file-eye"></i></a>
                      <a href="javascript:;" @click="downloadKajian(v)" v-if="v.is_draft == 'N' && moduleRole('download_template')" class="btn btn-icon alpha-blue border-blue mr-2" v-b-tooltip.hover title="Download Kajian Terminal"><i class="icon-download"></i></a>

                      <router-link :to="{ name: modulePage, params: { ...$route.params, kajianNo: v.id }, query: { viewType: 'FORM' } }" class="btn btn-icon alpha-warning border-warning" v-b-tooltip.hover title="Edit"><i class="icon-pencil4"></i></router-link>
                      <a href="javascript:;" v-if="v.is_draft == 'Y' && moduleRole('delete')" class="btn btn-icon alpha-warning border-warning ml-2" v-b-tooltip.hover title="Hapus Kajian Terminal" @click="deleteData(v.id)">
                        <i class="icon-trash"></i>
                      </a>
                    </td>
                  </tr>

                  <tr v-if="tData.page.total_data < 1">
                    <td colspan="10">
                      <h5 class="mb-0 text-center">Belum ada data monitoring</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-end" v-if="tData.page.total_data > tData.page.per_page">
              <b-pagination class="mb-0" v-model="pageNoTData" :per-page="tData.page.per_page" :total-rows="tData.page.total_data" />
            </div>
          </div>
        </div>

        <b-modal id="detail" size="xl" hide-footer title="Form Terminal">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">1. Gejala seperti mau muntah dan kesulitan bernafas
                  </h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Kegawatan Pernafasan</h6>
                        </div>
                        <div class="card-body">
                          <div class="det-check-item mt-3" v-for="(v, k) in dataModal.kegawatan_pernafasan" :key="`mg_${k}`">
                            <i class="icon-checkmark-circle text-success"></i>
                            <div class="d-flex flex-column">
                              <span>{{v.label||"-"}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Kehilangan tonus otot</h6>
                        </div>
                        <div class="card-body">
                          <div class="form-group mt-3">
                            <div class="det-check-item" v-for="(v, k) in dataModal.kehilangan_tonus_otot" :key="`mg_${k}`">
                              <i class="icon-checkmark-circle text-success"></i>
                              <div class="d-flex flex-column">
                                <span>{{v.label||"-"}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Nyeri</h6>
                        </div>
                        <div class="card-body">
                          <div class="form-group mt-3">
                            <span class="text-capitalize">
                              {{ dataModal.nyeri == 'Y' ? "Ya" : "Tidak" }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Perlambatan sirkulasi</h6>
                        </div>
                        <div class="card-body">
                          <div class="det-check-item mt-3" v-for="(v, k) in dataModal.perlambatan_sirkulasi" :key="`mg_${k}`">
                            <i class="icon-checkmark-circle text-success"></i>
                            <div class="d-flex flex-column">
                              <span>{{v.label||"-"}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">Faktor yang meningkatkan dan membangkitkan gejala
                    fisik</h5>
                </div>
                <div class="card-body">
                  <div class="form-group mt-3">
                    <div class="det-check-item" v-for="(v, k) in dataModal.faktor_gejala_fisik" :key="`fgf_${k}`">
                      <i class="icon-checkmark-circle text-success"></i>
                      <div class="d-flex flex-column">
                        <span>{{v.label||"-"}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">Manajemen gejala saat ini dan respon pasien</h5>
                </div>
                <div class="card-body">
                  <label>Masalah perawatan</label>
                  <div class="det-check-item" v-for="(v, k) in dataModal.manajemen_gejala" :key="`mg_${k}`">
                    <i class="icon-checkmark-circle text-success"></i>
                    <div class="d-flex flex-column">
                      <span>{{v.label||"-"}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card">
                <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">Orientasi spiritual pasien dan keluarga</h5>
                </div>
                <div class="card-body">
                  <label>Apakah perlu pelayanan spiritual?</label>
                  <div class="d-flex">
                    <span class="text-capitalize">{{ dataModal.orientasi_spiritual == 'Y' ? "Ya" : "Tidak" }}</span>

                    <div class="ml-2" v-if="dataModal.orientasi_spiritual == 'Y'">
                      , oleh {{ dataModal.orientasi_spiritual_note }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card">
                <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">Unsur dan kebutuhan spiritual pasien dan keluarga
                    seperti putus asa, penderitaan, rasa bersalah, atau pengampunan</h5>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                      <tr>
                        <td>Perlu didoakan</td>
                        <td>
                          <p class="mb-0 mt-2">
                            {{ dataModal.need_to_pray == 'Y' ? "Ya" : "Tidak" }} {{ dataModal.need_to_pray_note ? `, ${dataModal.need_to_pray_note}` : '' }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Perlu bimbingan rohani</td>
                        <td>
                          <p class="mb-0 mt-2">
                            {{ dataModal.need_spiritual_guide == 'Y' ? "Ya" : "Tidak" }} {{ dataModal.need_spiritual_guide_note ? `, ${dataModal.need_spiritual_guide_note}` : '' }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>Perlu pendampingan rohani</td>
                        <td>
                          <p class="mb-0 mt-2">
                            {{ dataModal.need_spiritual_assistance == 'Y' ? "Ya" : "Tidak" }} {{ dataModal.need_spiritual_assistance_note ? `, ${dataModal.need_spiritual_assistance_note}` : '' }}
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">Status psikososial pasien dan keluarga
                  </h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Apakah ada orang yang ingin dihubungi
                            saat ini?</h6>
                        </div>
                        <div class="card-body">
                          <div class="form-group mt-3">
                            <div class="d-flex">
                              <span>{{ dataModal.contact_person == 'Y' ? "Ya" : "Tidak" }}</span>

                              <template v-if="dataModal.contact_person == 'Y'">
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>Siapa</th>
                                        <th>Dimana</th>
                                        <th>Hubungan</th>
                                        <th>No. Selular</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{{ dataModal.contact_person_name }}</td>
                                        <td>{{ dataModal.contact_person_location }}</td>
                                        <td>{{ dataModal.contact_person_relation }}</td>
                                        <td>{{ dataModal.contact_person_phone }}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Bagaimana rencana perawatan selanjutnya?
                          </h6>
                        </div>
                        <div class="card-body">
                          <div class="form-group mt-3">
                            <div class="d-flex">
                              {{ objMrNextCarePlan[dataModal.next_care_plan] }}

                              <template v-if="dataModal.next_care_plan == 'DIRAWAT_DI_RUMAH'">
                                <div class="table-responsive mr-3">
                                  <table class="table">
                                    <tr>
                                      <td>
                                        <span>Apakah lingkungan rumah disiapkan?</span>

                                        <p class="mb-0 mt-2">{{ dataModal.is_environment_prepared == 'Y' ? 'Ya' : 'Tidak' }}</p>
                                      </td>

                                      <td v-if="dataModal.is_environment_prepared == 'Y'">
                                        <span>Apakah ada yang mampu merawat pasien di rumah?</span>

                                        <p class="mb-0 mt-2">{{ dataModal.is_any_people_care == 'Y' ? 'Ya' : 'Tidak' }}</p>
                                      </td>

                                      <td v-if="dataModal.is_environment_prepared == 'Y'">
                                        <span>Apakah perlu difasilitasi RS (Home Care)?</span>

                                        <p class="mb-0 mt-2">{{ dataModal.is_need_home_care == 'Y' ? 'Ya' : 'Tidak' }}</p>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </template>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Reaksi pasien atas penyakitnya</h6>
                        </div>
                        <div class="card-body">
                          <span class="d-block mt-3">Asesmen Informasi</span>
                          <div class="mt-2">
                            <div class="det-check-item" v-for="(v, k) in dataModal.reaksi_pasien_asesmen" :key="`mg_${k}`">
                              <i class="icon-checkmark-circle text-success"></i>
                              <div class="d-flex flex-column">
                                <span>{{v.label||"-"}}</span>
                              </div>
                            </div>
                          </div>

                          <span class="mt-3 d-block">Masalah Keperawatan</span>
                          <div class="mt-2">
                            <div class="det-check-item" v-for="(v, k) in dataModal.reaksi_pasien_keperawatan" :key="`mg_${k}`">
                              <i class="icon-checkmark-circle text-success"></i>
                              <div class="d-flex flex-column">
                                <span>{{v.label||"-"}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Reaksi keluarga atas penyakitnya</h6>
                        </div>
                        <div class="card-body">
                          <span class="d-block mt-3">Asesmen Informasi</span>
                          <div class="mt-2">
                            <div class="det-check-item" v-for="(v, k) in dataModal.reaksi_keluarga_asesmen" :key="`mg_${k}`">
                              <i class="icon-checkmark-circle text-success"></i>
                              <div class="d-flex flex-column">
                                <span>{{v.label||"-"}}</span>
                              </div>
                            </div>
                          </div>

                          <span class="mt-3 d-block">Masalah Keperawatan</span>
                          <div class="mt-2">
                            <div class="det-check-item" v-for="(v, k) in dataModal.reaksi_keluarga_keperawatan" :key="`mg_${k}`">
                              <i class="icon-checkmark-circle text-success"></i>
                              <div class="d-flex flex-column">
                                <span>{{v.label||"-"}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card">
                <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">Kebutuhan dukungan atau kelonggaran pelayanan
                    bagi pasien dan keluarga</h5>
                </div>
                <div class="card-body">
                  <div class="mt-2">
                    <div class="det-check-item" v-for="(v, k) in dataModal.kebutuhan_dukungan" :key="`kd_${k}`">
                      <i class="icon-checkmark-circle text-success"></i>
                      <div class="d-flex flex-column">
                        <span>{{v.label||"-"}}</span> <span v-if="v.value == 'OTHER'">, {{ v.note }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card">
                <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">Kebutuhan alternatif pelayanan bagi pasien dan
                    keluarga</h5>
                </div>
                <div class="card-body">
                  <div class="mt-2">
                    <div class="det-check-item" v-for="(v, k) in dataModal.kebutuhan_alternatif" :key="`mg_${k}`">
                      <i class="icon-checkmark-circle text-success"></i>
                      <div class="d-flex flex-column">
                        <span>{{v.label||"-"}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card">
                <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">Faktor resiko bagi keluarga yang ditinggalkan
                  </h5>
                </div>
                <div class="card-body">
                  <span class="d-block mt-3">Asesmen Informasi</span>
                  <div class="mt-2">
                    <div class="det-check-item" v-for="(v, k) in dataModal.resiko_keluarga_asesmen" :key="`mg_${k}`">
                      <i class="icon-checkmark-circle text-success"></i>
                      <div class="d-flex flex-column">
                        <span>{{v.label||"-"}}</span>
                      </div>
                    </div>
                  </div>

                  <span class="mt-3 d-block">Masalah Keperawatan</span>
                  <div class="mt-2">
                    <div class="det-check-item" v-for="(v, k) in dataModal.resiko_keluarga_keperawatan" :key="`mg_${k}`">
                      <i class="icon-checkmark-circle text-success"></i>
                      <div class="d-flex flex-column">
                        <span>{{v.label||"-"}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>

      </div>
    </template>
    <template v-else>
      <Form />
    </template>
  </div>
</div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Form from './Form.vue'

export default {
  extends: GlobalVue,
  components: {
    Form,
    PatientInfo
  },
  data() {
    return {
      patientData: {},
      loading: {
        patientInfo: false,
        coreData: false
      },

      rowReg: {},
      isMonitoring: false,
      modalConfig: {
        title: '',
        size: 'sm'
      },
      isModal: '',
      rowEdit: {},
      openModal: false,
      dataKajian: [],
      dataModal: {},

      mrKegawatanPernafasan: [],
      mrTonusOtot: [],
      mrPerlambatanSirkulasi: [],
      mrFaktorGejalaFisik: [],
      mrManajemenGejala: [],
      mrReaksiPasienAsesmen: [],
      mrReaksiPasienKeperawatan: [],
      mrReaksiKeluargaAsesmen: [],
      mrReaksiKeluargaKeperawatan: [],
      mrKebutuhanDukungan: [],
      mrKebutuhanAlternatif: [],
      mrResikoKeluargaAsesmen: [],
      mrResikoKeluargaKeperawatan: [],
      mrHubunganKeluarga: [],
      mrNextCarePlan: [],

      mrValidation: {},

      tData: {
        data: [],
        page: {
          total_data: 0,
          per_page: 10,
          current_page: 1,
          is_loading: false
        }
      },

      pageNoTData: 1,

      row: {},
      
      has_draft_data: false,
      isAddMonitoring: false,
    }
  },
  computed: {
    isListDetail() {
      return !this.$route.query.viewType
    },
    viewType() {
      return this.$route.query.viewType
    },
    objMrNextCarePlan(){
      return this.mrNextCarePlan.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    }
  },
  methods: {
    deleteData(id){
      this.$swal.fire({
        icon: 'warning',
        title: "Apakah Anda yakin ingin menghapus data ini?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
      }).then(btn => {
        if(btn.isConfirmed){
          this.$swal.fire({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: {
                type: "remove",
                id
              }
            },
          ).then(res => { 
            this.$swal.close()
            this.$swal({
              title: res.data.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then(result => {
              if (result.value) {
                this.getList()
              }
            })
          }).catch(()=>{
            this.$swal.close()

            return this.$swal({
              icon: 'error',
              text: 'Terjadi Kesalahan Yang Tidak Diketahui'
            })
          })
        }
      })
    },
    downloadKajian(data){
      this.$swal.fire({
        title: 'Processing...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
            type: "download-kajian-terminal",
            id_registrasi: this.$route.params.pageSlug,
          }
        },
        "POST"
      )
      .then(res => {
        const linkSource = res.data
        const downloadLink = document.createElement("a")
        downloadLink.href = linkSource
        downloadLink.download = `Kajian-Terminal-${new Date().getTime()}.pdf`
        downloadLink.click()

        this.$swal.close()
      })
      .catch(()=>{
        this.$swal.close()
        // this.globalVar.apiGenError( err.response.data )
      })
    },
    openDetail(data) {      
      this.$set(this, 'dataModal', data)
      this.$bvModal.show('detail')

      this.getForm()
    },
    back() {
      this.$router.push({
        name: 'RanapPasienPerawatan'
      }).catch({})
    },
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getList() {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-list",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this, 'loadingOverlay', false)
      })
    },

    getForm() {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form",
            id_registrasi: this.$route.params.pageSlug,
            id_kajian: this.$route.params.kajianNo
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    }
  },
  mounted() {
    this.getPatientInfo()
    if (this.viewType == "FORM") {
      this.getForm()
    } else {
      this.getList()
    }
  },
  watch: {
    $route() {
      this.getPatientInfo()
      if (this.viewType == "FORM") {
        this.getForm()
      } else {
        this.getList()
      }
    }
  }
}
</script>
